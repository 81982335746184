import React, { useEffect, useState } from 'react';
import Header from '../../common/header';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
  Trainingicon,
  Departmenticon,
  Booksicon,
  Knowledgeicon,
} from '../../api/imageurl';
import { useDisclosure } from '@mantine/hooks';

import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import {
  Modal,
  Button,
  Select,
  rem,
  Input,
  Group,
  ActionIcon,
  Text,
  FileInput,
  LoadingOverlay,
} from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import KnowledgebaseForm from './KnowledgebaseForm';
import { baseUrl } from '../../api/baseUrl';
import BlankTable from '../../components/BlankTable';
import { formatDate, n } from '../../GlobalFunctions';
import { Link } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import KnowledgeBaseEditForm from './KnowledgebaseEditForm';

const Knowledgebase = () => {
  const [buttonPress, setButtonPress] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [archive, setArchive] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const searchfile = <IconSearch style={{ width: rem(16), height: rem(16) }} />;

  const [opened, { open: openModal, close: closeModel }] = useDisclosure(false);
  const [policies, setPolicies] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');

  const [viewopened, { open: viewopen, close: viewclose }] =
    useDisclosure(false);

  const [openedEditModal, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);

  const Add = () => {
    setButtonPress(false);

    // form.reset();
    openModal();
  };
  const url = `${baseUrl}/knowledgebase`;

  useEffect(() => {
    fetch(`${url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log()
        // let department_array = data.map((a) => a.name);
        setAllRecords(data);
        setRecords(data);
        setIsLoading(true);
      });
  }, [refresh]);

  const handleArchive = (index) => {
    setArchive([]);
    setArchive(records[index]['archive']);
    // console.log(name, records[0]['archive']);
    viewopen(true);
  };

  const [index, setIndex] = useState(0);

  const handleDropDownFilter = (e) => {
    let list = [];
    e.value.map((value, index) => {
      list.push(value.name);
    });

    let filteredRecords = allRecords.filter((record) => {
      return list.includes(record.department);
    });
    // if (!filteredRecords.length) {
    // setRecords(allRecords);
    // } else {
    setRecords(filteredRecords);
    // }

    // console.log(e.value);

    if (!e.value.length) {
      setRecords(allRecords);
    }
    setSelectedDepartment(e.value);
  };

  const handleSearchFilter = (value) => {
    setSearchFilter(value);
    let list = [];
    if (value !== null) {
      list = allRecords.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );
    }
    setRecords(list);
    console.log(value, list);
  };

  const Edit = (index) => {
    setIndex(index);
    setButtonPress(false);
    openEditModal();
    // console.log(authors[index], index);
  };

  const handleDeleteModal = (index) => {
    setIsLoading(false);
    // return false;
    modals.openConfirmModal({
      title: 'Delete (' + module + ')',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete ?</Text>,
      labels: { confirm: 'Delete', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      closeOnClickOutside: false,
      onCancel: () => {
        setIsLoading(true);
      },
      onConfirm: () => {
        axios
          .delete(`${url}/${records[index].id}`)
          .then(function (response) {
            if (response.status === 200) {
              // authors[index] = values;
              setIsLoading(true);
              records.splice(index, 1);
              // delete authors[index];
              setRecords(records);
              console.log(records);
              notifications.show({
                color: response.data.alert,
                title: response.data.title,
                message: response.data.msg,
                autoClose: 3000,
                withCloseButton: true,
                position: 'bottom-left',
              });
              // setTimeout(() => setButtonPress(false), 1000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    });
  };

  const [vendorSelect, setVendorSelect] = useState([]);
  const [countrySelect, setCountrySelect] = useState([]);
  const [authorSelect, setAuthorSelect] = useState([]);
  const [sectorSelect, setSectorSelect] = useState([]);
  const select_url = `${baseUrl}/knowledgebase/form`;

  useEffect(() => {
    fetch(`${select_url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let vendor_dropdown = data.vendor.map((a) => {
          return {
            value: `${a.id}`,
            // label: a.name,
            label: a.name,
          };
        });

        let country_dropdown = data.country.map((a) => {
          return {
            value: `${a.region}`,
            // label: a.name,
            label: a.name,
          };
        });
        let author_dropdown = data.author.map((a) => {
          return {
            value: `${a.id}`,
            // label: a.name,
            label: a.name,
          };
        });

        let sector_dropdown = data.sector.map((a) => {
          return {
            value: `${a.id}`,
            region: a.region,
            label: a.name,
          };
        });

        setVendorSelect(vendor_dropdown);
        setCountrySelect(country_dropdown);
        setAuthorSelect(author_dropdown);
        setSectorSelect(sector_dropdown);

        // setSelect(data);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const module = 'Knowledge Base';
  return (
    <>
      <Header />
      <section>
        <div className="Policies-content setup-content">
          <div className="container container_box">
            <Breadcrumbs chain={[{ module: module, link: 'knowledgebase' }]} />

            <div className="setup-img authors-knowledge">
              <div
                className="sops-icons"
                dangerouslySetInnerHTML={{
                  __html: Knowledgeicon,
                }}
              />
              <h1>{module}</h1>
              <Button variant="filled" onClick={() => Add()}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Plusicon,
                  }}
                />{' '}
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className="policies-table">
          <div className="container container_box">
            <div className="polices-sops-content">
              <div className="polices-table">
                <div className="universal-filter">
                  <Input
                    placeholder="Search here"
                    value={searchFilter}
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    rightSection={<IconSearch size={16} />}
                  />
                </div>
                {isLoading ? (
                  <table>
                    <tr>
                      <th>S.No.</th>
                      <th>Document Name</th>
                      <th>Document Date</th>
                      <th className="date-uploaded">Date Uploaded</th>
                      <th className="knowledge-table-row">Author</th>
                      <th className="knowledge-table-row">Provider</th>
                      <th className="knowledge-table-row">Country</th>
                      <th>Sector</th>
                      <th>Download</th>
                      <th>Actions</th>
                    </tr>

                    {records.length ? (
                      records.map((value, index) => {
                        return (
                          <tr>
                            <td>{n(index + 1)}</td>
                            <td>{value.name}</td>
                            <td>{formatDate(value.date)}</td>
                            <td className="date-uploaded">
                              {formatDate(value.created_at)}
                            </td>
                            <td className="knowledge-table-row">
                              {value.author}
                            </td>
                            <td className="knowledge-table-row">
                              {value.vendor}
                            </td>
                            <td className="knowledge-table-row">
                              {value.country}
                            </td>
                            <td>{value.sector}</td>
                            <td>
                              <Link to={value.path} target="_blank">
                                <div
                                  className="author-plus"
                                  dangerouslySetInnerHTML={{
                                    __html: pdficon,
                                  }}
                                />
                              </Link>
                            </td>
                            <td>
                              <span onClick={() => Edit(index)}>
                                <ActionIcon variant="subtle" color="gray">
                                  <IconPencil
                                    style={{
                                      width: rem(16),
                                      height: rem(16),
                                    }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                              </span>
                              <span
                                className="dlte_txt"
                                onClick={() => handleDeleteModal(index)}
                              >
                                <ActionIcon variant="subtle" color="red">
                                  <IconTrash
                                    style={{
                                      width: rem(16),
                                      height: rem(16),
                                    }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="10" className="text-center">
                          Record Not Found
                        </td>
                      </tr>
                    )}
                  </table>
                ) : (
                  <BlankTable />
                )}

                <div className="select-result">
                  {/* <Pagination value={activePage} onChange={setPage} total={10} /> */}

                  <div className="number-result">
                    <span>Number of Results:</span>
                    <div class="select_drop">
                      <select class="selectpicker">
                        <option data-tokens="25">25</option>
                        <option data-tokens="50">50</option>
                        <option data-tokens="100">100</option>
                        <option data-tokens="all">All</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="polices-modal">
          {/*////////////////// Add //////////////////////////*/}
          <Modal.Root
            opened={opened}
            onClose={closeModel}
            centered
            size="lg"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            closeOnClickOutside={false}
            closeOnEscape={true}
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: modalplusicon,
                    }}
                  />{' '}
                  <b>{module}</b>
                </Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>
                <LoadingOverlay
                  visible={buttonPress}
                  zIndex={100}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                />
                <div className="author-search">
                  <KnowledgebaseForm
                    url={url}
                    policies={policies}
                    setPolicies={setPolicies}
                    setButtonPress={setButtonPress}
                    setRefresh={setRefresh}
                    vendorSelect={vendorSelect}
                    countrySelect={countrySelect}
                    authorSelect={authorSelect}
                    sectorSelect={sectorSelect}
                  />
                </div>
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>

          {/*////////////////// Edit //////////////////////////*/}
          <Modal.Root
            opened={openedEditModal}
            onClose={closeEditModal}
            centered
            size="lg"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            closeOnClickOutside={false}
            closeOnEscape={true}
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: modalplusicon,
                    }}
                  />{' '}
                  <b>{module}</b>
                </Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>
                <LoadingOverlay
                  visible={buttonPress}
                  zIndex={100}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                />
                <div className="author-search">
                  <KnowledgeBaseEditForm
                    url={`${url}/${records[index]?.id}`}
                    policies={allRecords}
                    setPolicies={setRecords}
                    setButtonPress={setButtonPress}
                    index={index}
                    closeEditModal={closeEditModal}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    vendorSelect={vendorSelect}
                    countrySelect={countrySelect}
                    authorSelect={authorSelect}
                    sectorSelect={sectorSelect}
                  />
                </div>
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>
        </div>
      </section>
    </>
  );
};

export default Knowledgebase;
