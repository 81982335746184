import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  MultiSelect,
  Select,
  rem,
  Input,
  Group,
  TextInput,
  FileInput,
} from '@mantine/core';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
} from '../../api/imageurl';
import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
// import { DateInput } from '@mantine/dates';
const Fields = ({ departmentArray, policyArray, form }) => {
  const icon = <IconChevronDown style={{ width: rem(16), height: rem(16) }} />;
  const calendaricon = (
    <IconCalendarDue style={{ width: rem(16), height: rem(16) }} />
  );
  const Uploadfile = <IconUpload style={{ width: rem(16), height: rem(16) }} />;

  return (
    <>
      <Group justify="space-between">
        <div className="author-select">
          <TextInput
            withAsterisk
            label="Document Name"
            placeholder="Select Document Name"
            // rightSection={icon}
            // data={policyArray}
            searchable
            nothingFoundMessage="Nothing found..."
            key={form.key('name')}
            {...form.getInputProps('name')}
          />
        </div>
        <div className="author-select">
          <DateInput
            withAsterisk
            defaultValue={new Date()}
            rightSection={calendaricon}
            valueFormat="DD MMM, YYYY"
            label="Document Date"
            placeholder="Date input"
            key={form.key('date')}
            {...form.getInputProps('date')}
          />
        </div>
      </Group>
      <Group mt="xl">
        <div className="author-select">
          <MultiSelect
            label="Department"
            withAsterisk
            rightSection={icon}
            placeholder="Select Department"
            data={departmentArray}
            key={form.key('department')}
            {...form.getInputProps('department')}
            searchable
          />
        </div>
        <div className="author-select">
          <FileInput
            withAsterisk
            rightSection={Uploadfile}
            label="File Uploads"
            placeholder="File Uploads"
            accept="application/pdf"
            clearable
            // placeholder="Upload files"
            rightSectionPointerEvents="none"
            key={form.key('file')}
            {...form.getInputProps('file')}
          />
        </div>
      </Group>
    </>
  );
};

export default Fields;
