import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  MultiSelect,
  Select,
  rem,
  Input,
  Group,
  FileInput,
  TextInput,
} from '@mantine/core';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
} from '../../api/imageurl';
import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { useForm, hasLength, isNotEmpty } from '@mantine/form';
import { baseUrl } from '../../api/baseUrl';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import Fields from './Fields';
// import { DateInput } from '@mantine/dates';
const SellSideResearchForm = ({
  policies,
  setPolicies,
  setButtonPress,
  url,
  setRefresh,
  departmentArray,
  vendorSelect,
  countrySelect,
  authorSelect,
  typeSelect,
  topicSelect,
  companySelect,
  sectorSelect,
}) => {
  const icon = <IconChevronDown style={{ width: rem(16), height: rem(16) }} />;
  const calendaricon = (
    <IconCalendarDue style={{ width: rem(16), height: rem(16) }} />
  );
  const Uploadfile = <IconUpload style={{ width: rem(16), height: rem(16) }} />;

  const allCompany = companySelect;
  const allSector = sectorSelect;

  const [companyFilter, setCompanyFilter] = useState(allCompany);
  const [sectorFilter, setSectorFilter] = useState(allSector);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      date: new Date(),
      // file: null,
    },

    validate: {
      name: isNotEmpty('Field must have been 2 letter'),
      // sector: isNotEmpty('Field must have been 2 letter'),
      topic: isNotEmpty('Please select Topic'),
      type: isNotEmpty('Please select Type'),
      // company: isNotEmpty('Please select Company'),
      // file: isNotEmpty('Please select file'),
      vendor: isNotEmpty('Please select Vendor'),
      country: isNotEmpty('Please select Country'),
      author: isNotEmpty('Please select Autor'),
    },
    onValuesChange: (values) => {
      const country = values.country;
      let sectorSelectF = allSector.filter(function (sector) {
        return sector.region === country;
      });

      companySelect = allCompany.filter(function (company) {
        return company.region === country;
      });

      setSectorFilter(sectorSelectF);
      setCompanyFilter(companySelect);
      // setCompanySelect(companySelect);
      // console.log(123, values, allSector, sectorFilter, sectorSelectF);
    },
  });

  const handleSubmit = (values) => {
    // form.clearErrors();
    setButtonPress(true);
    setRefresh(false);
    // console.log(values, authors);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios
      .post(`${url}`, values, config)
      .then(function (response) {
        if (response.status === 200) {
          notifications.show({
            color: response.data.alert,
            title: response.data.title,
            message: response.data.msg,
            autoClose: 3000,
            withCloseButton: true,
            position: 'bottom-left',
          });
          if (response.data.reset === 1) {
            form.reset();
          }
          setRefresh(true);
          setTimeout(() => setButtonPress(false), 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          error.response.data.error.map((value, index) => {
            form.setFieldError('name', true);
            form.setFieldError(value['field'], value['error']);
          });
          //   console.log(form.errors, error.response.data.error);
        }

        setTimeout(() => setButtonPress(false), 1000);
        if (error.response.data.status === 401) {
          notifications.show({
            color: 'red',
            title: error.response.data.error,
            autoClose: 2000,
          });
          setTimeout(() => setButtonPress(false), 1000);
        }

        if (error.response.data.status === 500) {
          notifications.show({
            color: 'red',
            title: error.response.message,
            autoClose: 2000,
          });
          setTimeout(() => setButtonPress(false), 1000);
        }
      });
  };

  const [select, setSelect] = useState([]);

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Fields
        form={form}
        url={url}
        vendorSelect={vendorSelect}
        countrySelect={countrySelect}
        authorSelect={authorSelect}
        typeSelect={typeSelect}
        topicSelect={topicSelect}
        companySelect={companyFilter}
        sectorSelect={sectorFilter}
      />
      <Button type="submit" variant="filled" mt="2rem" mb="1rem">
        {' '}
        <div
          dangerouslySetInnerHTML={{
            __html: Plusicon,
          }}
        />
        Add
      </Button>
    </form>
  );
};

export default SellSideResearchForm;
