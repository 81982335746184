// import React, { useState, useEffect } from 'react';
// import {
//   Modal,
//   Button,
//   MultiSelect,
//   Select,
//   rem,
//   Input,
//   Group,
//   FileInput,
//   TextInput,
// } from '@mantine/core';
// import {
//   policiesicon,
//   Plusicon,
//   modalplusicon,
//   eyemodalicon,
//   pdficon,
//   eyeicon,
// } from '../../api/imageurl';
// import {
//   IconChevronDown,
//   IconCalendarDue,
//   IconSearch,
//   IconUpload,
// } from '@tabler/icons-react';
// import { DateInput } from '@mantine/dates';
// import { useForm, hasLength, isNotEmpty } from '@mantine/form';
// import { baseUrl } from '../../api/baseUrl';
// import { notifications } from '@mantine/notifications';
// import axios from 'axios';
// // import { DateInput } from '@mantine/dates';
// const BookForm = ({
//   policies,
//   setPolicies,
//   setButtonPress,
//   url,
//   setRefresh,
//   departmentArray,
//   booksArray,
// }) => {
//   const icon = <IconChevronDown style={{ width: rem(16), height: rem(16) }} />;
//   const calendaricon = (
//     <IconCalendarDue style={{ width: rem(16), height: rem(16) }} />
//   );
//   const Uploadfile = <IconUpload style={{ width: rem(16), height: rem(16) }} />;

//   const [policyArray, setPolicyArray] = useState([]);
//   const policy_url = `${baseUrl}/setup/policylist`;
//   useEffect(() => {
//     fetch(`${policy_url}`, {
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // setPolicy(data);
//         let policy_array = data.map((a) => a.name);
//         setPolicyArray(policy_array);
//       });
//   }, []);

//   const form = useForm({
//     mode: 'uncontrolled',
//     initialValues: {
//       name: '',
//       file: null,
//       department: [],
//       category: '',
//     },

//     validate: {
//       name: isNotEmpty('Field must have been 2 letter'),
//       //   date: isNotEmpty('Enter your date'),
//       department: isNotEmpty('Please select department'),
//       category: isNotEmpty('Please select category'),
//       file: isNotEmpty('Please select file'),
//     },
//   });

//   const handleSubmit = (values) => {
//     // form.clearErrors();
//     setButtonPress(true);
//     setRefresh(false);
//     // console.log(values, authors);

//     const config = {
//       headers: {
//         'content-type': 'multipart/form-data',
//       },
//     };

//     axios
//       .post(`${url}`, values, config)
//       .then(function (response) {
//         if (response.status === 200) {
//           policies.unshift(values);
//           //   console.log(values, authors);
//           setPolicies(policies);
//           notifications.show({
//             color: response.data.alert,
//             title: response.data.title,
//             message: response.data.msg,
//             autoClose: 3000,
//             withCloseButton: true,
//             position: 'bottom-left',
//           });
//           if (response.data.reset === 1) {
//             form.reset();
//           }
//           setRefresh(true);
//           setTimeout(() => setButtonPress(false), 1000);
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//         if (error.response.status === 422) {
//           error.response.data.error.map((value, index) => {
//             form.setFieldError('name', true);
//             form.setFieldError(value['field'], value['error']);
//           });
//           //   console.log(form.errors, error.response.data.error);
//         }

//         setTimeout(() => setButtonPress(false), 1000);
//         if (error.response.data.status === 401) {
//           notifications.show({
//             color: 'red',
//             title: error.response.data.error,
//             autoClose: 2000,
//           });
//           setTimeout(() => setButtonPress(false), 1000);
//         }
//       });
//   };
//   return (
//     <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
//       <Group justify="space-between">
//         <div className="author-select">
//           <TextInput
//             placeholder={'Document Name'}
//             label="Document Name"
//             withAsterisk
//             error={form.errors.name && 'The field has already been taken.'}
//             data-autofocus="true"
//             key={form.key('name')}
//             {...form.getInputProps('name')}
//           />
//         </div>
//         <div className="author-select">
//           <Select
//             label="Category"
//             withAsterisk
//             placeholder="Select Category"
//             data={booksArray}
//             key={form.key('category')}
//             {...form.getInputProps('category')}
//             searchable
//           />
//         </div>
//       </Group>
//       <Group mt="xl">
//         <div className="author-select">
//           <MultiSelect
//             label="Department"
//             withAsterisk
//             rightSection={icon}
//             placeholder="Select Department"
//             data={departmentArray}
//             key={form.key('department')}
//             {...form.getInputProps('department')}
//             searchable
//           />
//         </div>
//         <div className="author-select">
//           <FileInput
//             withAsterisk
//             rightSection={Uploadfile}
//             label="File Uploads"
//             placeholder="File Uploads"
//             accept="application/pdf"
//             clearable
//             // placeholder="Upload files"
//             rightSectionPointerEvents="none"
//             key={form.key('file')}
//             {...form.getInputProps('file')}
//           />
//         </div>
//       </Group>
//       <Button type="submit" variant="filled" mt="2rem" mb="1rem">
//         {' '}
//         <div
//           dangerouslySetInnerHTML={{
//             __html: Plusicon,
//           }}
//         />
//         Add
//       </Button>
//     </form>
//   );
// };

// export default BookForm;








import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  MultiSelect,
  Select,
  rem,
  Input,
  Group,
  FileInput,
} from '@mantine/core';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
} from '../../api/imageurl';
import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { useForm, hasLength, isNotEmpty } from '@mantine/form';
import { baseUrl } from '../../api/baseUrl';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import Fields from './Fields';
// import { DateInput } from '@mantine/dates';

const BookForm = ({
  policies,
  setPolicies,
  setButtonPress,
  url,
  departmentArray,
  booksCategoryArray,
  setRefresh,

}) => {
  const icon = <IconChevronDown style={{ width: rem(16), height: rem(16) }} />;
  const calendaricon = (
    <IconCalendarDue style={{ width: rem(16), height: rem(16) }} />
  );
  const Uploadfile = <IconUpload style={{ width: rem(16), height: rem(16) }} />;

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      date: new Date(),
      file: null,
      department: [],
    },

    validate: {
      name: isNotEmpty('Field must have been 2 letter'),
      //   date: isNotEmpty('Enter your date'),
      department: isNotEmpty('Enter your department'),
      // file: isNotEmpty('Please select file'),
    },
  });

  const handleSubmit = (values) => {
    // console.log(values);
    // return false;
    // setRefresh(false);
    setButtonPress(true);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    axios
      .post(`${url}`, values, config)
      .then(function (response) {
        if (response.status === 200) {
          setRefresh(true);
          policies.unshift(response.data.data);
          //   console.log(values, authors);
          setPolicies(policies);
          // console.log(policies);
          notifications.show({
            color: response.data.alert,
            title: response.data.title,
            message: response.data.msg,
            autoClose: 3000,
            withCloseButton: true,
            position: 'bottom-left',
          });
          if (response.data.reset === 1) {
            form.reset();
          }
          setTimeout(() => setButtonPress(false), 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          error.response.data.error.map((value, index) => {
            form.setFieldError('name', true);
            form.setFieldError(value['field'], value['error']);
          });
          //   console.log(form.errors, error.response.data.error);
        }

        setTimeout(() => setButtonPress(false), 1000);
        if (error.response.data.status === 401) {
          notifications.show({
            color: 'red',
            title: error.response.data.error,
            autoClose: 2000,
          });
          setTimeout(() => setButtonPress(false), 1000);
        }
      });
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Fields
        form={form}
        departmentArray={departmentArray}
        booksCategoryArray={booksCategoryArray}
      />
      <Button type="submit" variant="filled" mt="2rem" mb="1rem">
        {' '}
        <div
          dangerouslySetInnerHTML={{
            __html: Plusicon,
          }}
        />
        Add
      </Button>
    </form>
  );
};

export default BookForm;