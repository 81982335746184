import { useState, useEffect } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import LogoAkseer from '../../assets/images/Logo-Akseer.svg';
import { nprogress, NavigationProgress } from '@mantine/nprogress';
import { Button, Group, Menu, rem, Modal } from '@mantine/core';
import { useCookies } from 'react-cookie';
import Ellipseicon from '../../../src/assets/images/Ellipse 1.svg';
import { Link } from 'react-router-dom';
// import { NavigationProgress } from '@mantine/nprogress';
import '@mantine/nprogress/styles.css';
import { useNavigate } from 'react-router-dom';
import { IconSettings, IconLogout } from '@tabler/icons-react';
import axios from 'axios';
import sessionIcon from '../../../src/assets/images/sessionIcon.svg';
import { useDisclosure } from '@mantine/hooks';
import { useIdle } from '@mantine/hooks';

export default function Header() {
  const redirect = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies([
    'accessToken',
    'userInformation',
  ]);

  axios.interceptors.request.use(function (config) {
    const token = `Bearer ${cookies.accessToken}`;
    config.headers.Authorization = token;
    return config;
  });

  const [opened, setOpened] = useState(false);
  //const dispatch = useDispatch();

  const handleLogout = () => {
    console.log(cookies.accessToken);
    localStorage.clear();
    removeCookies('userInformation');
    removeCookies('accessToken', { path: '/' });
    // redirect('/', { replace: true });
  };

  const [sessionTimeOut, { open, close }] = useDisclosure(false);
  if (cookies.accessToken == undefined) {
    return (
      <>
        <Navigate to="/" replace="true" />
      </>
    );
    // redirect('/', { replace: true });
  }
  return (
    <>
      <div className="header-top">
        <div className="header-nav">
          <div className="container container_box">
            <div className="header-main-bar">
              <div className="header-bar">
                <Link to="/brain">
                  <img src={LogoAkseer} />
                </Link>
              </div>
              <Menu opened={opened} onChange={setOpened}>
                <Menu.Target>
                  <Button variant="transparent">
                    <img src={Ellipseicon} alt="" />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <IconSettings
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    <Link to="#">Setting</Link>
                  </Menu.Item>
                  <Menu.Item
                    leftSection={
                      <IconLogout style={{ width: rem(14), height: rem(14) }} />
                    }
                    onClick={handleLogout}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal.Root
        opened={open}
        onClose={close}
        closeOnClickOutside={false}
        closeOnEscape={true}
        centered
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <b>Session Timeout</b>
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <div className="session-alert-content sessionAlertContent">
              <p className="para">
                <span className="iconSpan">
                  <img src={sessionIcon} />
                </span>
                <span>
                  Your session has expired due to inactivity. Please login
                  again.
                </span>
                <button onClick={() => handleLogout()} className="buttonOK">
                  Ok
                </button>
              </p>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root> */}
    </>
  );

  // nprogress.start();
}
