import React, { useState } from 'react';
import { Sidebar, SubMenu, Menu, MenuItem } from 'react-pro-sidebar';
import {
  setupimg,
  Authorsicon,
  Plusicon,
  authorssellicon,
  bookcategoryicon,
  countrylisticon,
  companylisticon,
  authorsetupicon,
  modalplusicon,
  departmentlistsicon,
  departmentmanualsicon,
  policylisticon,
  reportresearchicon,
  typeresearchicon,
  vendorsknowledgeicon,
  vendorsresearchicon,
} from '../../api/imageurl';
import { Link, NavLink } from 'react-router-dom';
const SidebarComponent = () => {
  const [toggled, setToggled] = useState(false);
  return (
    <div className="col-md-3 pr-0">
      <div className="knowledge-content-left">
        <Sidebar className={`app ${toggled ? 'toggled' : ''}`}>
          <main>
            <Menu>
              <div className="setup-side-menu">
              <MenuItem>
              <NavLink to="/brain/setup/authorsknowladgebase">
                <div
                  dangerouslySetInnerHTML={{
                    __html: authorsetupicon,
                  }}
                />
                    Authors - Knowledge Base
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/authorssellsideresearch">
                <div
                  dangerouslySetInnerHTML={{
                    __html: authorssellicon,
                  }}
                />
                    Authors - Sellside Research
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/bookscategories">
                <div
                  dangerouslySetInnerHTML={{
                    __html: bookcategoryicon,
                  }}
                />
                    Books Categories
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/countrylist">
                <div
                  dangerouslySetInnerHTML={{
                    __html: countrylisticon,
                  }}
                />
                    Country List
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/companylist">
                <div
                  dangerouslySetInnerHTML={{
                    __html: companylisticon,
                  }}
                />
                    Company List
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/departmentlist">
                <div
                  dangerouslySetInnerHTML={{
                    __html: departmentlistsicon,
                  }}
                />
                    Department Lists
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/departmentmanuals">
                <div
                  dangerouslySetInnerHTML={{
                    __html: departmentmanualsicon,
                  }}
                />
                    Department Manuals
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/policylist">
                <div
                  dangerouslySetInnerHTML={{
                    __html: policylisticon,
                  }}
                />
                    Policy List
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/reporttopicsellsideresearch">
                <div
                  dangerouslySetInnerHTML={{
                    __html: reportresearchicon,
                  }}
                />
                    Report Topic - Sellside Research
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/reporttypesellsideresearch">
                <div
                  dangerouslySetInnerHTML={{
                    __html: typeresearchicon,
                  }}
                />
                    Report Type - Sellside Research
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/vendorsknowledgebase">
                <div
                  dangerouslySetInnerHTML={{
                    __html: vendorsknowledgeicon,
                  }}
                />
                    Vendors - Knowledge Base
                  </NavLink>
                </MenuItem>
              </div>
              <div className="setup-side-menu">
              <MenuItem>
                  <NavLink to="/brain/setup/vendorssellsideresearch">
                <div
                  dangerouslySetInnerHTML={{
                    __html: vendorsresearchicon,
                  }}
                />
                    Vendors - Sellside Research
                  </NavLink>
                </MenuItem>
              </div>
            </Menu>
          </main>
        </Sidebar>
      </div>
    </div>
  );
};

export default SidebarComponent;
