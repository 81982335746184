import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  MultiSelect,
  Select,
  rem,
  Input,
  Group,
  FileInput,
} from '@mantine/core';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
} from '../../api/imageurl';
import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { useForm, hasLength, isNotEmpty } from '@mantine/form';
import { baseUrl } from '../../api/baseUrl';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import Fields from './Fields';
// import { DateInput } from '@mantine/dates';
const PolicyEditForm = ({
  policies,
  setPolicies,
  setButtonPress,
  url,
  setRefresh,
  departmentArray,
  policyArray,
  index,
  closeEditModal,
  refresh,
}) => {
  const department_ids = policies[index].department_id;
  const policy_id = policies[index].policy_id;
  //console.log(1234, index, department_ids.split(','), [...department_ids]);
  const icon = <IconChevronDown style={{ width: rem(16), height: rem(16) }} />;
  const calendaricon = (
    <IconCalendarDue style={{ width: rem(16), height: rem(16) }} />
  );
  const Uploadfile = <IconUpload style={{ width: rem(16), height: rem(16) }} />;

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: `${policy_id}`,
      date: new Date(policies[index].date),
      // file: null,
      department: department_ids.split(','),
    },

    validate: {
      name: isNotEmpty('Policy must have been 2 letter'),
      //   date: isNotEmpty('Enter your date'),
      department: isNotEmpty('Enter your department'),
      // file: isNotEmpty('Please select file'),
    },
  });

  const handleEditSubmit = (values) => {
    // form.clearErrors();
    // console.log(values);
    // return false;
    setButtonPress(true);
    // setRefresh(false);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'content-type': 'application/x-www-form-urlencoded',
      },
    };

    axios
      .put(`${url}`, values, config)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          // policies[index] = values;
          //   console.log(values, authors);
          // setPolicies(policies);
          notifications.show({
            color: response.data.alert,
            title: response.data.title,
            message: response.data.msg,
            autoClose: 3000,
            withCloseButton: true,
            position: 'bottom-left',
          });
          if (response.data.reset === 1) {
            // form.reset();
          }
          closeEditModal();
          setRefresh(!refresh);
          setTimeout(() => setButtonPress(false), 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          error.response.data.error.map((value, index) => {
            form.setFieldError('name', true);
            form.setFieldError(value['field'], value['error']);
          });
          //   console.log(form.errors, error.response.data.error);
        }

        setTimeout(() => setButtonPress(false), 1000);
        if (error.response.data.status === 401) {
          notifications.show({
            color: 'red',
            title: error.response.data.error,
            autoClose: 2000,
          });
          setTimeout(() => setButtonPress(false), 1000);
        }
      });
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleEditSubmit(values))}>
      <Fields
        form={form}
        departmentArray={departmentArray}
        policyArray={policyArray}
      />
      <Button type="submit" variant="filled" mt="2rem" mb="1rem">
        {' '}
        <div
          dangerouslySetInnerHTML={{
            __html: Plusicon,
          }}
        />
        Edit
      </Button>
    </form>
  );
};

export default PolicyEditForm;
