import React, { useEffect } from 'react';
import Header from '../common/header';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
  setupicon,
  rightarrowicon,
  policiesicon,
  Trainingicon,
  Departmenticon,
  Specificicon,
  Knowledgeicon,
  Booksicon,
  Magazinesicon,
  sellsideicon,
} from '../api/imageurl';
import { Tabs } from '@mantine/core';
import { nprogress, NavigationProgress } from '@mantine/nprogress';
import Ellipseicon from '../../src/assets/images/Ellipse 1.svg';
import { sentenceCase } from '../../src/GlobalFunctions';
import { Helmet } from 'react-helmet';

const Brain = () => {
  const [cookies, setCookies, removeCookies] = useCookies([
    'accessToken',
    'userInformation',
  ]);

  const links = [
    {
      title: 'Policies and SOPs',
      link: '/brain/policiessops',
      icon: policiesicon,
    },
    {
      title: 'General Training Material',
      link: '/brain/generaltrainingmaterial',
      icon: Trainingicon,
    },
    {
      title: 'Department Manuals',
      link: '/brain/departmentmanuals',
      icon: Departmenticon,
    },
    {
      title: 'Department Specific Training Material',
      link: '/brain/departmentspecifictrainingmaterial',
      icon: Specificicon,
    },
    {
      title: 'Knowledge Base',
      link: '/brain/knowledgebase',
      icon: Knowledgeicon,
    },
    {
      title: 'Books',
      link: '/brain/books',
      icon: Booksicon,
    },
    {
      title: 'Magazines',
      link: '/brain/magazines',
      icon: Magazinesicon,
    },
    {
      title: 'Sell Side Research',
      link: '/brain/sellsideresearch',
      icon: sellsideicon,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>CMS | Brain</title>
      </Helmet>
      <Header />
      {/* <NavigationProgress /> */}
      {/* <Outlet /> */}
      <section>
        <div className="main-brain">
          <div className="container1">
            <div className="brain-content">
              <div className="container container_box">
                <div className="morning-text">
                  <h1>
                    Welcome,{' '}
                    {cookies?.userInformation === undefined
                      ? ''
                      : sentenceCase(cookies.userInformation.name)}
                  </h1>
                </div>
                <div className="setup-text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: setupicon,
                    }}
                  />
                  <span>
                    <Link to="/brain/setup/authorsknowladgebase">Setup</Link>
                  </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: rightarrowicon,
                    }}
                  />
                </div>
                <div className="brain-updates">
                  <div className="row">
                    <div className="col-md-12 col-lg-5 col-sm-12">
                      <div className="key-updates">
                        <div className="keys-heading">
                          <h1>Key Updates</h1>
                          <span>
                            <a href="/">Mark all as read</a>
                          </span>
                        </div>
                        <div className="keys-tabs">
                          <Tabs defaultValue="All">
                            <Tabs.List>
                              <Tabs.Tab value="All">All</Tabs.Tab>
                              <Tabs.Tab value="Policies">
                                Policies and SOPs
                              </Tabs.Tab>
                              <Tabs.Tab value="GeneralTraining">
                                General Training Material
                              </Tabs.Tab>
                              <Tabs.Tab value="DepartmentManuals">
                                Department Manuals
                              </Tabs.Tab>
                            </Tabs.List>

                            <Tabs.Panel value="All">
                              <div className="main-tabs">
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tabs.Panel>

                            <Tabs.Panel value="Policies">
                              <div className="main-tabs">
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tabs.Panel>

                            <Tabs.Panel value="GeneralTraining">
                              <div className="main-tabs">
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tabs.Panel>

                            <Tabs.Panel value="DepartmentManuals">
                              <div className="main-tabs">
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="key-policies marks-lead">
                                  <div className="policies-img">
                                    <img src={Ellipseicon} />
                                  </div>
                                  <div className="policies-content">
                                    <span>Policies and SOPs</span>
                                    <h1>Lorem Ipsum dolar set amet</h1>
                                    <p>
                                      This is a dummy news update post content.
                                      This is a dummy news update post content.
                                    </p>
                                    <div className="policies-read">
                                      <span>Nov-4-2021</span>
                                      <span>
                                        <a href="/">Read More</a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tabs.Panel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-7 col-sm-12 brain-sizing">
                      <div className="policies-category">
                        {links.map((value, index) => {
                          return (
                            <Link to={value.link}>
                              <div className="policies-boxes">
                                <div
                                  className="text-center"
                                  dangerouslySetInnerHTML={{
                                    __html: value.icon,
                                  }}
                                />
                                <h1>{value.title}</h1>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brain;
