export const sentenceCase = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const n = (n) => {
  return n > 9 ? '' + n : '0' + n;
};

export const formatDate = (date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  let month_name = months[d.getMonth()];
  // 08 January, 2024
  return day + ' ' + month_name + ',' + ' ' + year;

  return [year, month, day].join('-');
};
