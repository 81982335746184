import React from 'react';
import { LoadingOverlay, Box } from '@mantine/core';

const BlankTable = () => {
  return (
    <Box pos="relative">
      <LoadingOverlay
        visible={true}
        zIndex={100}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <table>
        <thead>
          <tr>
            <th>S.no</th>
            <th>Authors</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01</td>
            <td>Alpha Capital</td>
          </tr>
          <tr>
            <td>02</td>
            <td>Akseer Research</td>
          </tr>
          <tr>
            <td>03</td>
            <td>AKD Securities</td>
          </tr>
          <tr>
            <td>04</td>
            <td>JS Global</td>
          </tr>
          <tr>
            <td>05</td>
            <td>Fortune Securities</td>
          </tr>
          <tr>
            <td>06</td>
            <td>Habib Invest</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default BlankTable;
