// import React, { useState, useEffect } from 'react';
// import Header from '../../../common/header';
// import {
//   setupimg,
//   Authorsicon,
//   Plusicon,
//   modalplusicon,
//   companylisticon,
// } from '../../../api/imageurl';
// import { Button, Modal, TextInput, LoadingOverlay, Box, Text, ActionIcon, rem } from '@mantine/core';
// import { useDisclosure } from '@mantine/hooks';
// import { useForm } from '@mantine/form';
// import Sidebar from '../sidebar';
// import { IconXboxX } from '@tabler/icons-react';
// import { IconPencil, IconTrash } from "@tabler/icons-react";
// import { modals } from "@mantine/modals";
// import { notifications } from '@mantine/notifications';
// import axios from 'axios';
// import { useCookies } from 'react-cookie';
// import { formPostModal } from '../../../GlobalFunctionsApi';
// import SetupEditForm from "../SetupEditForm";
// import BlankTable from '../../../components/BlankTable';
// import { baseUrl } from '../../../api/baseUrl';
// import SetupForm from '../SetupForm';
// import { Helmet } from "react-helmet";
// import Breadcrumbs from '../../../components/Breadcrumbs';

// const CompanyList = () => {
//   const [cookies, setCookies, removeCookies] = useCookies(['accessToken']);
//   const [buttonPress, setButtonPress] = useState(false);
//   const [authors, setAuthors] = useState([{}]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [index, setIndex] = useState(0);

//   const [openedModal, { open: openModal, close: closeModal }] =
//     useDisclosure(false);

//     const [openedEditModal, { open: openEditModal, close: closeEditModal }] =
//     useDisclosure(false);

//   const url = `${baseUrl}/setup/companylist`;

//   useEffect(() => {
//     // if (company.symbol != undefined) {
//     fetch(`${url}`, {
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         // if (data.length) {
//         setAuthors(data);
//         setIsLoading(true);
//         // setCheck(true);
//         // console.log(123, authors);
//         // }
//       });
//     // }
//   }, []);

//   const Edit = (index) => {
//     setIndex(index);
//     setButtonPress(false);
//     openEditModal();
//     // console.log(authors[index], index);
//   };

//   const Add = () => {
//     setButtonPress(false);
//     // form.reset();
//     openModal();
//   };
//   const module = 'Company List';

//   const handleDeleteModal = (index) => {
//     setIsLoading(false);
//     console.log(authors[index]);
//     // return false;
//     modals.openConfirmModal({
//       title: "Delete (" + module + ")",
//       centered: true,
//       children: <Text size="sm">Are you sure you want to delete ?</Text>,
//       labels: { confirm: "Delete", cancel: "No don't delete it" },
//       confirmProps: { color: "red" },
//       closeOnClickOutside: false,
//       onCancel: () => {
//         setIsLoading(true);
//       },
//       onConfirm: () => {
//         axios
//           .delete(`${url}/${authors[index].id}`)
//           .then(function (response) {
//             if (response.status === 200) {
//               // authors[index] = values;
//               setIsLoading(true);
//               authors.splice(index, 1);
//               // delete authors[index];
//               setAuthors(authors);
//               console.log(authors);
//               notifications.show({
//                 color: response.data.alert,
//                 title: response.data.title,
//                 message: response.data.msg,
//                 autoClose: 3000,
//                 withCloseButton: true,
//                 position: "bottom-left",
//               });
//               // setTimeout(() => setButtonPress(false), 1000);
//             }
//           })
//           .catch(function (error) {
//             console.log(error);
//           });
//       },
//     });
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])

//   return (
//     <>
//     <Helmet>
//         <title>
//           CMS | Setup | {module}
//         </title>
//       </Helmet>
//       <Header />
//       <section>
//         <div className="setup-content">
//           <div className="container container_box">
//             <Breadcrumbs chain={[{ module: module, link: 'setup/companylist' }]} />
//             <div className="setup-img">
//               <div
//                 dangerouslySetInnerHTML={{
//                   __html: setupimg,
//                 }}
//               />
//               <h1>Setup</h1>
//             </div>
//           </div>
//         </div>
//         <div className="setup-Knowledge">
//           <div className="container container_box">
//             <div className="row">
//               <Sidebar />
//               <div className="col-md-9">
//                 <div className="knowledge-content-right">
//                   <div className="authors-knowledge">
//                     <div
//                       className="knowledge-setup-icon"
//                       dangerouslySetInnerHTML={{
//                         __html: companylisticon,
//                       }}
//                     />
//                     <h1>{module}</h1>
//                     <Button variant="filled" onClick={() => Add()}>
//                       <div
//                         dangerouslySetInnerHTML={{
//                           __html: Plusicon,
//                         }}
//                       />{' '}
//                       Add
//                     </Button>
//                   </div>
//                   <div className="author-table">
//                     {isLoading ? (
//                       <table>
//                         <thead>
//                           <tr>
//                             <th>S.no</th>
//                             <th>{module}</th>
//                             <th>Actions</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {authors.length ? (
//                             authors.map((value, index) => {
//                               index++;
//                               return (
//                                 <tr>
//                                   <td>{index}</td>
//                                   <td>{value.name}</td>
//                                   <td>
//                                     <span onClick={() => Edit(index - 1)}>
//                                       <ActionIcon variant="subtle" color="gray">
//                                         <IconPencil
//                                           style={{
//                                             width: rem(16),
//                                             height: rem(16),
//                                           }}
//                                           stroke={1.5}
//                                         />
//                                       </ActionIcon>
//                                     </span>
//                                     <span
//                                       className="dlte_txt"
//                                       onClick={() =>
//                                         handleDeleteModal(index - 1)
//                                       }
//                                     >
//                                       <ActionIcon variant="subtle" color="red">
//                                         <IconTrash
//                                           style={{
//                                             width: rem(16),
//                                             height: rem(16),
//                                           }}
//                                           stroke={1.5}
//                                         />
//                                       </ActionIcon>
//                                     </span>
//                                   </td>
//                                 </tr>
//                               );
//                             })
//                           ) : (
//                             <tr>
//                               <td colspan="2" className="text-center">
//                                 Record Not Found
//                               </td>
//                             </tr>
//                           )}
//                         </tbody>
//                       </table>
//                     ) : (
//                       <BlankTable />
//                     )}
//                     <div className="select-result">
//                       <div className="number-result">
//                         <span>Number of Results:</span>
//                         <div className="select_drop">
//                           <select className="selectpicker">
//                             <option data-tokens="25">25</option>
//                             <option data-tokens="50">50</option>
//                             <option data-tokens="100">100</option>
//                             <option data-tokens="all">All</option>
//                           </select>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/*////////////////// Add //////////////////////////*/}
//         <Modal.Root
//           opened={openedModal}
//           onClose={closeModal}
//           centered
//           closeOnClickOutside={false}
//           closeOnEscape={true}
//           overlayProps={{
//             backgroundOpacity: 0.55,
//             blur: 3,
//           }}
//         >
//           <Modal.Overlay />
//           <Modal.Content>
//             <Modal.Header>
//               <Modal.Title>
//                 <span
//                   dangerouslySetInnerHTML={{
//                     __html: modalplusicon,
//                   }}
//                 />{' '}
//                 {module}
//               </Modal.Title>
//               <Modal.CloseButton
//                 closeButtonProps={{
//                   icon: <IconXboxX size={20} stroke={1.5} />,
//                 }}
//               />
//             </Modal.Header>
//             <Modal.Body>
//               <LoadingOverlay
//                 visible={buttonPress}
//                 zIndex={100}
//                 overlayProps={{ radius: 'sm', blur: 2 }}
//               />
//               <div className="author_modal_content">
//                 <div className="author-select-field">
//                   {/* <h1>Add Authors - Knowledge Base</h1> */}
//                   <SetupForm
//                     placeholder={module}
//                     url={url}
//                     setButtonPress={setButtonPress}
//                     authors={authors}
//                     setAuthors={setAuthors}
//                   />
//                 </div>
//               </div>
//             </Modal.Body>
//           </Modal.Content>
//         </Modal.Root>

//         {/*////////////////// Edit //////////////////////////*/}
//         <Modal.Root
//           opened={openedEditModal}
//           onClose={closeEditModal}
//           centered
//           closeOnClickOutside={false}
//           closeOnEscape={true}
//           overlayProps={{
//             backgroundOpacity: 0.55,
//             blur: 3,
//           }}
//         >
//           <Modal.Overlay />
//           <Modal.Content>
//             <Modal.Header>
//               <Modal.Title>
//                 <span
//                   dangerouslySetInnerHTML={{
//                     __html: modalplusicon,
//                   }}
//                 />{" "}
//                 {module}
//               </Modal.Title>
//               <Modal.CloseButton
//                 closeButtonProps={{
//                   icon: <IconXboxX size={20} stroke={1.5} />,
//                 }}
//               />
//             </Modal.Header>
//             <Modal.Body>
//               <LoadingOverlay
//                 visible={buttonPress}
//                 zIndex={100}
//                 overlayProps={{ radius: "sm", blur: 2 }}
//               />
//               <div className="author_modal_content">
//                 <div className="author-search edit-input-field">
//                   {/* <h1>Add Authors - Knowledge Base</h1> */}
//                   <SetupEditForm
//                     placeholder={module}
//                     url={url + "/" + authors[index]?.id}
//                     index={index}
//                     setButtonPress={setButtonPress}
//                     authors={authors}
//                     setAuthors={setAuthors}
//                     closeEditModal={closeEditModal}
//                   />
//                 </div>
//               </div>
//             </Modal.Body>
//           </Modal.Content>
//         </Modal.Root>
//       </section>
//     </>
//   );
// };

// export default CompanyList;













import React, { useState, useEffect } from 'react';
import Header from '../../../common/header';
import {
  setupimg,
  Authorsicon,
  Plusicon,
  modalplusicon,
} from '../../../api/imageurl';
import {
  Button,
  Modal,
  TextInput,
  LoadingOverlay,
  Box,
  Text,
  ActionIcon,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import Sidebar from '../sidebar';
import { IconXboxX } from '@tabler/icons-react';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { formPostModal } from '../../../GlobalFunctionsApi';
import SetupEditForm from '../SetupEditForm';
import BlankTable from '../../../components/BlankTable';
import { baseUrl } from '../../../api/baseUrl';
import SetupForm from '../SetupForm';

const CompanyList = () => {
  const [cookies, setCookies, removeCookies] = useCookies(['accessToken']);
  const [buttonPress, setButtonPress] = useState(false);
  const [authors, setAuthors] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const [openedEditModal, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);

  const url = `${baseUrl}/setup/companylist`;

  useEffect(() => {
    // if (company.symbol != undefined) {
    fetch(`${url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // if (data.length) {
        setAuthors(data);
        setIsLoading(true);
        // setCheck(true);
        // console.log(123, authors);
        // }
      });
    // }
  }, []);

  const Edit = (index) => {
    setIndex(index);
    setButtonPress(false);
    openEditModal();
    // console.log(authors[index], index);
  };

  const Add = () => {
    setButtonPress(false);
    // form.reset();
    openModal();
  };
  const module = 'Company List';

  const handleDeleteModal = (index) => {
    setIsLoading(false);
    console.log(authors[index]);
    // return false;
    modals.openConfirmModal({
      title: 'Delete (' + module + ')',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete ?</Text>,
      labels: { confirm: 'Delete', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      closeOnClickOutside: false,
      onCancel: () => {
        setIsLoading(true);
      },
      onConfirm: () => {
        axios
          .delete(`${url}/${authors[index].id}`)
          .then(function (response) {
            if (response.status === 200) {
              // authors[index] = values;
              setIsLoading(true);
              authors.splice(index, 1);
              // delete authors[index];
              setAuthors(authors);
              console.log(authors);
              notifications.show({
                color: response.data.alert,
                title: response.data.title,
                message: response.data.msg,
                autoClose: 3000,
                withCloseButton: true,
                position: 'bottom-left',
              });
              // setTimeout(() => setButtonPress(false), 1000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    });
  };

  return (
    <>
      <Header />
      <section>
        <div className="setup-content">
          <div className="container container_box">
            <div className="setup-img">
              <div
                dangerouslySetInnerHTML={{
                  __html: setupimg,
                }}
              />
              <h1>Setup</h1>
            </div>
          </div>
        </div>
        <div className="setup-Knowledge">
          <div className="container container_box">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="knowledge-content-right">
                  <div className="authors-knowledge">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Authorsicon,
                      }}
                    />
                    <h1>{module}</h1>
                    <Button variant="filled" onClick={() => Add()}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Plusicon,
                        }}
                      />{' '}
                      Add
                    </Button>
                  </div>
                  <div className="author-table polices-table">
                    {isLoading ? (
                      <table>
                        <thead>
                          <tr>
                            <th>S.no</th>
                            <th>Name</th>
                            <th>Ticker</th>
                            <th>Sector</th>
                            <th>Industry</th>
                            <th>Industry Group</th>
                            <th>Region</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {authors.length ? (
                            authors.map((value, index) => {
                              index++;
                              return (
                                <tr>
                                  <td>{index}</td>
                                  <td>{value.name}</td>
                                  <td>{value.ticker}</td>
                                  <td>{value.sector}</td>
                                  <td>{value.industry}</td>
                                  <td>{value.industry_group}</td>
                                  <td>{value.region}</td>
                                  <td className="edit_dlt_btn">
                                    <span onClick={() => Edit(index - 1)}>
                                      <ActionIcon variant="subtle" color="gray">
                                        <IconPencil
                                          style={{
                                            width: rem(16),
                                            height: rem(16),
                                          }}
                                          stroke={1.5}
                                        />
                                      </ActionIcon>
                                    </span>
                                    <span
                                      className="dlte_txt"
                                      onClick={() =>
                                        handleDeleteModal(index - 1)
                                      }
                                    >
                                      <ActionIcon variant="subtle" color="red">
                                        <IconTrash
                                          style={{
                                            width: rem(16),
                                            height: rem(16),
                                          }}
                                          stroke={1.5}
                                        />
                                      </ActionIcon>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="2" className="text-center">
                                Record Not Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <BlankTable />
                    )}
                    <div className="select-result">
                      <div className="number-result">
                        <span>Number of Results:</span>
                        <div className="select_drop">
                          <select className="selectpicker">
                            <option data-tokens="25">25</option>
                            <option data-tokens="50">50</option>
                            <option data-tokens="100">100</option>
                            <option data-tokens="all">All</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*////////////////// Add //////////////////////////*/}
        <Modal.Root
          opened={openedModal}
          onClose={closeModal}
          centered
          closeOnClickOutside={false}
          closeOnEscape={true}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>
                <span
                  dangerouslySetInnerHTML={{
                    __html: modalplusicon,
                  }}
                />{' '}
                {module}
              </Modal.Title>
              <Modal.CloseButton
                closeButtonProps={{
                  icon: <IconXboxX size={20} stroke={1.5} />,
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <LoadingOverlay
                visible={buttonPress}
                zIndex={100}
                overlayProps={{ radius: 'sm', blur: 2 }}
              />
              <div className="author_modal_content">
                <div className="author-select-field">
                  {/* <h1>Add Authors - Knowledge Base</h1> */}
                  <SetupForm
                    placeholder={module}
                    url={url}
                    setButtonPress={setButtonPress}
                    authors={authors}
                    setAuthors={setAuthors}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>

        {/*////////////////// Edit //////////////////////////*/}
        <Modal.Root
          opened={openedEditModal}
          onClose={closeEditModal}
          centered
          closeOnClickOutside={false}
          closeOnEscape={true}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>
                <span
                  dangerouslySetInnerHTML={{
                    __html: modalplusicon,
                  }}
                />{' '}
                {module}
              </Modal.Title>
              <Modal.CloseButton
                closeButtonProps={{
                  icon: <IconXboxX size={20} stroke={1.5} />,
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <LoadingOverlay
                visible={buttonPress}
                zIndex={100}
                overlayProps={{ radius: 'sm', blur: 2 }}
              />
              <div className="author_modal_content">
                <div className="author-select-field">
                  {/* <h1>Add Authors - Knowledge Base</h1> */}
                  <SetupEditForm
                    placeholder={module}
                    url={url + '/' + authors[index].id}
                    index={index}
                    setButtonPress={setButtonPress}
                    authors={authors}
                    setAuthors={setAuthors}
                    closeEditModal={closeEditModal}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      </section>
    </>
  );
};

export default CompanyList;
