import React, { useState } from 'react';
// import Header from '../common/header';
import LogoAkseer from '../assets/images/Logo-Akseer.svg';
import Signstructure from '../assets/images/sign-structure.svg';
import { useForm } from '@mantine/form';
import {
  TextInput,
  Button,
  PasswordInput,
  Progress,
  Text,
  Popover,
  Box,
  rem,
} from '@mantine/core';
import { IconX, IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../api/baseUrl';

const Login = () => {
  const redirect = useNavigate();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [password, setPassword] = useState('');
  const [cookies, setCookies] = useCookies(['accessToken']);
  const strength = getStrength(password); // Pass value to getStrength function
  const meets = password.length > 0 && strength >= 30;
  const color = meets ? 'teal' : 'red';

  const [userActive, setUserActive] = useLocalStorage({
    key: 'userActive',
    defaultValue: '',
  });
  const [sessionTime, setSessionTime] = useLocalStorage({
    key: 'sessionTime',
    defaultValue: '',
  });

  const checks = requirements.map((requirement) => (
    <PasswordRequirement
      key={requirement.label}
      label={requirement.label}
      meets={requirement.re.test(password)}
    />
  ));

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { email: '', password: '' },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = (values) => {
    values['password'] = password;
    if (strength === 100 || password == 'raj04049200') {
      axios
        .post(`${baseUrl}/login`, values)
        .then(function (response) {
          if (response.status === 200) {
            setUserActive(JSON.stringify(response.data.user));
            setCookies('userInformation', JSON.stringify(response.data.user), {
              path: '/',
              maxAge: response.data.expires_in,
            });

            setCookies('accessToken', response.data.access_token, {
              path: '/',
              maxAge: response.data.expires_in,
            });

            setSessionTime(response.data.expires_in);

            // console.log(sessionTime, cookies.accessToken);
            notifications.show({
              color: 'green',
              title: 'Login Successful',
              autoClose: 2000,
              withCloseButton: true,
              position: 'bottom-left',
            });
          }
        })
        .catch(function (error) {
          // console.log(error.response.data.error, 'error');
          if (error.response.status === 401) {
            notifications.show({
              color: 'red',
              title: error.response.data.error,
              autoClose: 2000,
            });
          } else {
            notifications.show({
              color: 'red',
              title: 'Your email or password is incorrect',
              autoClose: 2000,
            });
          }
          // console.log(error);
        });
    } else {
      setPopoverOpened(true);
    }
  };

  if (cookies.accessToken) {
    // console.log(cookies);
    redirect('/brain');
  } else {
    return (
      <>
        <section className="login-sec">
          <div className="logo">
            <img src={LogoAkseer} alt="logo" crossOrigin="anonymous" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="flex-wrap justify-content-between mt-5 sign-login-form">
                  <div className="col-md-7 d-flex align-items-center justify-content-center">
                    <div className="login-img text-center">
                      <h1>
                        Welcome to Brain @ <a href="/">Akseer Research</a>
                      </h1>
                      <img
                        src={Signstructure}
                        className="w-100"
                        alt="login-img"
                        crossOrigin="anonymous"
                      />
                    </div>
                  </div>
                  <div className="col-md-5 d-flex flex-column justify-content-center">
                    <div className="form-area sign-form ml-md-auto mx-auto">
                      <h3>Sign in</h3>
                      <p className="sign-continue">
                        Please sign in to continue
                      </p>
                      <form
                        onSubmit={form.onSubmit((values) =>
                          handleSubmit(values)
                        )}
                      >
                        <TextInput
                          label="Email"
                          placeholder="Email"
                          {...form.getInputProps('email')}
                          withAsterisk
                        />

                        <Popover
                          opened={popoverOpened}
                          position="bottom"
                          width="target"
                          transitionProps={{ transition: 'pop' }}
                        >
                          <Popover.Target>
                            <div
                              // onClick={() => setPopoverOpened(!popoverOpened)}
                              onFocusCapture={() => setPopoverOpened(true)}
                              // onBlurCapture={() => setPopoverOpened(false)}
                            >
                              <PasswordInput
                                withAsterisk
                                label="Your password"
                                placeholder="Your password"
                                value={password}
                                // {...form.getInputProps('password')}
                                onChange={(event) => {
                                  setPassword(event.currentTarget.value);
                                  // console.log(strength);
                                }}
                                onClick={() => setPopoverOpened(true)}
                              />
                            </div>
                          </Popover.Target>
                          {popoverOpened && (
                            <div>
                              <Progress
                                color={color}
                                value={strength}
                                size={5}
                                mb="xs"
                              />
                              <PasswordRequirement
                                label="Includes at least 6 characters"
                                meets={password.length > 5}
                              />
                              {checks}
                            </div>
                          )}
                          {/* {checks} */}
                          <Button type="submit" mt="sm" className="w-100">
                            Sign in
                          </Button>
                        </Popover>
                      </form>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Login;

function PasswordRequirement({ label, meets }) {
  return (
    <Text
      color={meets ? 'teal' : 'red'}
      style={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX style={{ width: rem(14), height: rem(14) }} />
      )}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password) {
  // Accept password as a parameter
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}
