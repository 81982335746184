import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ chain }) => {
  return (
    <div className="bread-crumbs">
      <span>
        <Link to="/brain" className="notlink">
          Brain /{' '}
        </Link>
      </span>
      {chain.map((value, index) => {
        return (
          <span>
            <Link to={`/brain/${value.link}`} className="yeslink">
              {value.module}
            </Link>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
