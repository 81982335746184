import React from 'react';
import Header from '../../common/header';
import { Outlet } from 'react-router-dom';
import { setupimg, Authorsicon, Plusicon } from '../../api/imageurl';
import { Button, Modal, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modalplusicon } from '../../api/imageurl';
const Setup = () => {
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  return (
    <>
      <Header />
      <section>
        <div className="setup-content">
          <div className="container container_box">
            <div className="setup-img">
              <div
                dangerouslySetInnerHTML={{
                  __html: setupimg,
                }}
              />
              <h1>Setup</h1>
            </div>
          </div>
        </div>
        <div className="setup-Knowledge">
          <div className="container container_box">
            <div className="row">
              <div className="col-md-3 pr-0">
                <div className="knowledge-content-left"></div>
              </div>
              <div className="col-md-9">
                <div className="knowledge-content-right">
                  <div className="authors-knowledge">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Authorsicon,
                      }}
                    />
                    <h1>Authors - Knowledge Base</h1>
                    <Button variant="filled" onClick={openModal}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Plusicon,
                        }}
                      />{' '}
                      Add
                    </Button>
                  </div>
                  <div className="author-table">
                    <table>
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Authors</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>01</td>
                          <td>Alpha Capital</td>
                        </tr>
                        <tr>
                          <td>02</td>
                          <td>Akseer Research</td>
                        </tr>
                        <tr>
                          <td>03</td>
                          <td>AKD Securities</td>
                        </tr>
                        <tr>
                          <td>04</td>
                          <td>JS Global</td>
                        </tr>
                        <tr>
                          <td>05</td>
                          <td>Fortune Securities</td>
                        </tr>
                        <tr>
                          <td>06</td>
                          <td>Habib Invest</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="select-result">
                      <div className="number-result">
                        <span>Number of Results:</span>
                        <div className="select_drop">
                          <select className="selectpicker">
                            <option data-tokens="25">25</option>
                            <option data-tokens="50">50</option>
                            <option data-tokens="100">100</option>
                            <option data-tokens="all">All</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal.Root
          opened={openedModal}
          onClose={closeModal}
          centered
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>
                <span
                  dangerouslySetInnerHTML={{
                    __html: modalplusicon,
                  }}
                />{' '}
                Authors - Knowledge Base
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <div className="author_modal_content">
                <div className="author-search">
                  <h1>Add Authors - Knowledge Base</h1>
                  <TextInput placeholder="Akseer Research" />
                  <Button variant="filled" className="mt-4">
                    {' '}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Plusicon,
                      }}
                    />
                    Add
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      </section>
    </>
  );
};

export default Setup;
