import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  MultiSelect,
  Select,
  rem,
  Input,
  Group,
  FileInput,
  TextInput,
} from '@mantine/core';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
} from '../../api/imageurl';
import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { DateInput } from '@mantine/dates';
import { baseUrl } from '../../api/baseUrl';
// import { DateInput } from '@mantine/dates';
const Fields = ({
  form,
  vendorSelect,
  countrySelect,
  authorSelect,
  sectorSelect,
}) => {
  const icon = <IconChevronDown style={{ width: rem(16), height: rem(16) }} />;
  const calendaricon = (
    <IconCalendarDue style={{ width: rem(16), height: rem(16) }} />
  );
  const Uploadfile = <IconUpload style={{ width: rem(16), height: rem(16) }} />;

  const [select, setSelect] = useState([]);

  return (
    <>
      <Group justify="space-between">
        <div className="author-select">
          <TextInput
            placeholder={'Document Name'}
            label="Document Name"
            withAsterisk
            error={form.errors.name && 'The field has already been taken.'}
            data-autofocus="true"
            key={form.key('name')}
            {...form.getInputProps('name')}
          />
        </div>
        <div className="author-select">
          <DateInput
            withAsterisk
            defaultValue={new Date()}
            rightSection={calendaricon}
            valueFormat="DD MMM, YYYY"
            label="Document Date"
            placeholder="Date input"
            key={form.key('date')}
            {...form.getInputProps('date')}
          />
        </div>
      </Group>
      <Group mt="xl">
        <div className="author-select">
          <Select
            label="Vendor"
            withAsterisk
            rightSection={icon}
            placeholder="Select Vendor"
            data={vendorSelect}
            key={form.key('vendor')}
            {...form.getInputProps('vendor')}
            searchable
          />
        </div>
        <div className="author-select">
          <Select
            label="Country"
            withAsterisk
            placeholder="Select Country"
            data={countrySelect}
            rightSection={icon}
            key={form.key('country')}
            {...form.getInputProps('country')}
            searchable
          />
        </div>
      </Group>
      <Group mt="xl">
        <div className="author-select">
          <Select
            placeholder={'Sector Name'}
            label="Sector Name"
            withAsterisk
            rightSection={icon}
            data={sectorSelect}
            searchable
            error={form.errors.sector && 'The field has already been taken.'}
            data-autofocus="true"
            key={form.key('sector')}
            {...form.getInputProps('sector')}
          />
        </div>
        <div className="author-select">
          <Select
            label="Author"
            withAsterisk
            rightSection={icon}
            placeholder="Select Author"
            data={authorSelect}
            key={form.key('author')}
            {...form.getInputProps('author')}
            searchable
          />
        </div>
      </Group>
      <Group mt="xl">
        <div className="author-select file_width_size">
          <FileInput
            withAsterisk
            rightSection={Uploadfile}
            label="File Uploads"
            placeholder="File Uploads"
            accept="application/pdf"
            clearable
            // placeholder="Upload files"
            rightSectionPointerEvents="none"
            key={form.key('file')}
            {...form.getInputProps('file')}
          />
        </div>
      </Group>
    </>
  );
};

export default Fields;
