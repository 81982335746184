import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Button, Modal, TextInput, LoadingOverlay, Box } from '@mantine/core';
import { Plusicon } from '../../api/imageurl';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
const SetupEditForm = ({
  url,
  setButtonPress,
  placeholder,
  authors,
  setAuthors,
  index,
  closeEditModal,
}) => {
  const form = useForm({
    mode: 'uncontrolled',
    // validateInputOnChange: true,
    validateInputOnBlur: true,
    clearInputErrorOnChange: false,
    initialValues: authors[index],

    validate: {
      // name: (value) => (/^[a-zA-Z]+$/.test(value) ? '2 letters' : null),
      name: (value) =>
        value.length < 2
          ? 'This field must have at least 2 letters'
          : /^[a-zA-Z0-9 ]+$/.test(value)
          ? null
          : 'Only alphabatic characters allow',
    },
  });

  const handleSubmit = (values) => {
    // form.clearErrors();
    setButtonPress(true);
    // console.log(values);

    axios
      .put(`${url}`, values)
      .then(function (response) {
        if (response.status === 200) {
          authors[index] = values;
          //   console.log(values, authors);
          setAuthors(authors);
          notifications.show({
            color: response.data.alert,
            title: response.data.title,
            message: response.data.msg,
            autoClose: 3000,
            withCloseButton: true,
            position: 'bottom-left',
          });
          if (response.data.reset === 1) {
            form.setInitialValues(values);
            closeEditModal();
            // form.reset();
          }

          setTimeout(() => setButtonPress(false), 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          error.response.data.error.map((value, index) => {
            form.setFieldError('name', true);
            form.setFieldError(value['field'], value['error']);
          });
          //   console.log(form.errors, error.response.data.error);
        }

        setTimeout(() => setButtonPress(false), 1000);
        if (error.response.data.status === 401) {
          notifications.show({
            color: 'red',
            title: error.response.data.error,
            autoClose: 2000,
          });
          setTimeout(() => setButtonPress(false), 1000);
        }
      });
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <TextInput
        placeholder={placeholder}
        withAsterisk
        error={form.errors.name && 'The name has already been taken.'}
        data-autofocus="true"
        key={form.key('name')}
        {...form.getInputProps('name')}
      />
      <Button variant="filled" className="mt-4" type="submit">
        {' '}
        <div
          dangerouslySetInnerHTML={{
            __html: Plusicon,
          }}
        />
        Edit
      </Button>
    </form>
  );
};

export default SetupEditForm;
