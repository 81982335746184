import React, { useEffect, useState } from 'react';
import Header from '../../common/header';
import {
  policiesicon,
  Plusicon,
  modalplusicon,
  eyemodalicon,
  pdficon,
  eyeicon,
} from '../../api/imageurl';
import { useDisclosure } from '@mantine/hooks';

import {
  IconChevronDown,
  IconCalendarDue,
  IconSearch,
  IconUpload,
} from '@tabler/icons-react';
import { IconPencil, IconTrash } from "@tabler/icons-react";
import {
  Modal,
  Button,
  Select,
  rem,  
  ActionIcon,
  Input,
  Group,
  FileInput,
  LoadingOverlay,
  Text,
} from '@mantine/core';
import PolicyForm from './PolicyForm';
import { baseUrl } from '../../api/baseUrl';
import BlankTable from '../../components/BlankTable';
import { n } from '../../GlobalFunctions';
import { Link } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import { modals } from '@mantine/modals';
import axios from 'axios';
import { notifications } from '@mantine/notifications';
import PolicyEditForm from './PolicyEditForm';
import { Helmet } from 'react-helmet';


const Policiessops = () => {
  const [buttonPress, setButtonPress] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [archive, setArchive] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const searchfile = <IconSearch style={{ width: rem(16), height: rem(16) }} />;

  const [opened, { open: openModal, close: closeModel }] = useDisclosure(false);
  // const [openedEdit, { openEdit: openEditModal, closeEdit: closeEditModel }] = useDisclosure(false);
  const [policies, setPolicies] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchFilterOnName, setSearchFilterOnName] = useState('');
  const [index, setIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const [viewopened, { open: viewopen, close: viewclose }] =
    useDisclosure(false);
  const [openedEditModal, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);

  const url = `${baseUrl}/policiesandsops`;

  useEffect(() => {
    fetch(`${url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(123, data);
        // let department_array = data.map((a) => a.name);
        setAllRecords(data);
        setRecords(data);
        setIsLoading(true);
      });
  }, [refresh]);

  const handleArchive = (index) => {
    setArchive([]);
    setArchive(records[index]['archive']);
    // console.log(name, records[0]['archive']);
    viewopen(true);
  };

  const [departments, setDepartments] = useState([]);
  const [departmentArray, setDepartmentArray] = useState([]);
  const department_url = `${baseUrl}/setup/departmentlists`;

  useEffect(() => {
    fetch(`${department_url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let department_array = data.map((a) => {
          return {
            value: `${a.id}`,
            label: a.name,
          };
        });

        let departments_object = data.map((item) => ({
          name: item.name,
          code: item.id,
        }));
        setDepartmentArray(department_array);
        setDepartments(departments_object);
        // console.log(departments_object);
        // setDepartments(data);
      });
  }, []);
  const [policyArray, setPolicyArray] = useState([]);
  const policy_url = `${baseUrl}/setup/policylist`;
  useEffect(() => {
    fetch(`${policy_url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // setPolicy(data);
        let policy_array = data.map((a) => {
          return {
            value: `${a.id}`,
            // label: a.name,
            label: a.name,
          };
        });
        console.log(policy_array);
        setPolicyArray(policy_array);
      });
  }, []);

  const handleDropDownFilter = (e) => {
    let list = [];
    e.value.map((value, index) => {
      list.push(value.name);
    });

    let filteredRecords = allRecords.filter((record) => {
      return list.includes(record.department);
    });
    // if (!filteredRecords.length) {
    // setRecords(allRecords);
    // } else {
    setRecords(filteredRecords);
    // }

    // console.log(e.value);

    if (!e.value.length) {
      setRecords(allRecords);
    }
    setSelectedDepartment(e.value);
  };

  const handleSearchFilterOnName = (value) => {
    setSearchFilterOnName(value);
    const keys = ['name'];
    let list = [];
    if (value !== null) {
      list = allRecords.filter((post) =>
        keys.some((key) => post[key].includes(value))
      );
    }
    setRecords(list);
  };
  const handleSearchFilter = (value) => {
    setSearchFilter(value);
    let list = [];
    if (value !== null) {
      list = allRecords.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );
    }
    setRecords(list);
    console.log(value, list);
  };
  const Add = () => {
    setButtonPress(false);
    openModal();
  };
  const Edit = (index) => {
    setIndex(index);
    setButtonPress(false);
    openEditModal();
    // console.log(authors[index], index);
  };

  const handleDeleteModal = (index) => {
    setIsLoading(false);
    // return false;
    modals.openConfirmModal({
      title: 'Delete (' + module + ')',
      centered: true,
      children: <Text size="sm">Are you sure you want to delete ?</Text>,
      labels: { confirm: 'Delete', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      closeOnClickOutside: false,
      onCancel: () => {
        setIsLoading(true);
      },
      onConfirm: () => {
        axios
          .delete(`${url}/${records[index].id}`)
          .then(function (response) {
            if (response.status === 200) {
              // authors[index] = values;
              setIsLoading(true);
              records.splice(index, 1);
              // delete authors[index];
              setRecords(records);
              console.log(records);
              notifications.show({
                color: response.data.alert,
                title: response.data.title,
                message: response.data.msg,
                autoClose: 3000,
                withCloseButton: true,
                position: 'bottom-left',
              });
              // setTimeout(() => setButtonPress(false), 1000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const module = 'Policies and SOPs';
  
  return (
    <>
    <Helmet>
        <title>CMS | {module}</title>
      </Helmet>
      <Header />
      <section>
        <div className="Policies-content setup-content">
          <div className="container container_box">
            <Breadcrumbs chain={[{ module: module, link: 'policiessops' }]} />
            <div className="setup-img authors-knowledge">
              <div
                className="sops-icons"
                dangerouslySetInnerHTML={{
                  __html: policiesicon,
                }}
              />
              <h1>{module}</h1>
              <Button variant="filled" onClick={() => Add()}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: Plusicon,
                  }}
                />{' '}
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className="policies-table">
          <div className="container container_box">
            <div className="polices-sops-content">
              {/* <div className="date-range"> */}
              {/* <span>Date Range:</span> */}
              {/* <DateInput valueFormat="YYYY MMM DD" placeholder="Date input" /> -
              <DateInput valueFormat="YYYY MMM DD" placeholder="Date input" /> */}
              {/* </div> */}
              <div className="polices-table">
                <div className="universal-filter">
                  <Input
                    placeholder="Search here"
                    value={searchFilter}
                    onChange={(e) => handleSearchFilter(e.target.value)}
                    rightSection={<IconSearch size={16} />}
                  />
                </div>
                {isLoading ? (
                  <table>
                    <tr>
                      <th>S.No.</th>
                      <th>Document Name</th>
                      <th>Date Uploaded</th>
                      <th>Applicable to</th>
                      <th>View Archives</th>
                      <th>Download</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th className="second-input-row">
                        <Input
                          placeholder="Search name"
                          value={searchFilterOnName}
                          onChange={(e) =>
                            handleSearchFilterOnName(e.target.value)
                          }
                          rightSection={<IconSearch size={16} />}
                        />
                      </th>
                      <th></th>
                      <th>
                        <MultiSelect
                          value={selectedDepartment}
                          onChange={(e) => handleDropDownFilter(e)}
                          options={departments}
                          optionLabel="name"
                          filter
                          placeholder="Select Department"
                          maxSelectedLabels={0}
                          className="w-full md:w-20rem policy-input"
                          // maxSelectedLabels={3}
                        />
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    {records.length ? (
                      records.map((value, index) => {
                        return (
                          <tr>
                            <td>{n(index + 1)}</td>
                            <td>{value.name}</td>
                            <td>{value.date}</td>
                            <td>
                              <span className="light-green">
                                {value.department}
                              </span>
                            </td>
                            <td>
                              <div
                                className="eye-view"
                                onClick={() => handleArchive(index)}
                              >
                                <div
                                  className="author-plus"
                                  dangerouslySetInnerHTML={{
                                    __html: eyeicon,
                                  }}
                                />
                                View
                              </div>
                            </td>
                            <td>
                              <Link to={value.path} target="_blank">
                                <div
                                  className="author-plus"
                                  dangerouslySetInnerHTML={{
                                    __html: pdficon,
                                  }}
                                />
                              </Link>
                            </td>
                            <td>
                              <span onClick={() => Edit(index)}>
                                <ActionIcon variant="subtle" color="gray">
                                  <IconPencil
                                    style={{
                                      width: rem(16),
                                      height: rem(16),
                                    }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                              </span>
                              <span
                                className="dlte_txt"
                                onClick={() => handleDeleteModal(index)}
                              >
                                <ActionIcon variant="subtle" color="red">
                                  <IconTrash
                                    style={{
                                      width: rem(16),
                                      height: rem(16),
                                    }}
                                    stroke={1.5}
                                  />
                                </ActionIcon>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="7" className="text-center">
                          Record Not Found
                        </td>
                      </tr>
                    )}
                  </table>
                ) : (
                  <BlankTable />
                )}

                <div className="select-result">
                  {/* <Pagination value={activePage} onChange={setPage} total={10} /> */}
                  {/* <Paginate
                    // onPageChange={paginate}
                    // pageCount={Math.ceil(reports.length / postsPerPage)}
                    previousLabel={'Prev'}
                    nextLabel={'Next'}
                    containerClassName={'pagination'}
                    pageLinkClassName={'page-number'}
                    previousLinkClassName={'page-number'}
                    nextLinkClassName={'page-number'}
                    activeLinkClassName={'active'}
                  /> */}
                  <div className="number-result">
                    <span>Number of Results:</span>
                    <div class="select_drop">
                      <select class="selectpicker">
                        <option data-tokens="25">25</option>
                        <option data-tokens="50">50</option>
                        <option data-tokens="100">100</option>
                        <option data-tokens="all">All</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="polices-modal">

          {/*///////////////////////////////// Add /////////////*/}
          <Modal.Root
            opened={opened}
            onClose={closeModel}
            centered
            size="lg"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            closeOnClickOutside={false}
            closeOnEscape={true}
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: modalplusicon,
                    }}
                  />{' '}
                  <b>{module}</b>
                </Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>
                <LoadingOverlay
                  visible={buttonPress}
                  zIndex={100}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                />
                <div className="author-search">
                  <PolicyForm
                    url={url}
                    departmentArray={departmentArray}
                    policies={policies}
                    setPolicies={setPolicies}
                    setButtonPress={setButtonPress}
                    policyArray={policyArray}
                    setRefresh={setRefresh}
                  />
                </div>
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>

          {/*///////////////////////////////// Edit /////////////*/}
          <Modal.Root
            opened={openedEditModal}
            onClose={closeEditModal}
            centered
            size="lg"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            closeOnClickOutside={false}
            closeOnEscape={true}
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: modalplusicon,
                    }}
                  />{' '}
                  <b>{module}</b>
                </Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>
                <LoadingOverlay
                  visible={buttonPress}
                  zIndex={100}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                />
                <div className="author-search">
                  <PolicyEditForm
                    url={`${url}/${records[index]?.id}`}
                    departmentArray={departmentArray}
                    policies={allRecords}
                    setPolicies={setRecords}
                    setButtonPress={setButtonPress}
                    policyArray={policyArray}
                    index={index}
                    closeEditModal={closeEditModal}
                    setRefresh={setRefresh}
                    refresh={refresh}
                  />
                </div>
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>

          {/* ///////////////////  View Archives  ////////////// */}
          <Modal.Root
            opened={viewopened}
            onClose={viewclose}
            centered
            size="lg"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            closeOnClickOutside={false}
            closeOnEscape={true}
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <div className="view-title">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: eyeicon,
                      }}
                    />{' '}
                    <b>View Archives</b>
                  </div>
                </Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>
                <div className="author-search">
                  <div className="Policies-heading">
                    <h1>{module}</h1>
                  </div>
                </div>
                <div className="view-table polices-table">
                  <table>
                    <tr>
                      <th>S.No.</th>
                      <th>Document Name</th>
                      <th>Date Uploaded</th>
                      {/* <th>Department</th> */}
                      <th>Download</th>
                    </tr>
                    {archive.length ? (
                      archive.map((value, index) => {
                        return (
                          <tr>
                            <td>{n(index + 1)}</td>
                            <td>{value.name}</td>
                            <td>{value.date}</td>
                            {/* <td>
                              <span className="light-green">
                                {value.department}
                              </span>
                            </td> */}
                            <td>
                              <Link to={value.path} target="_blank">
                                <div
                                  className="author-plus"
                                  dangerouslySetInnerHTML={{
                                    __html: pdficon,
                                  }}
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6" className="text-center">
                          Record Not Found
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>
        </div>
      </section>
    </>
  );
};

export default Policiessops;
