import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from 'react-router-dom';
import Brain from './brain';
import Setup from './brain/setup';
import Login from './layouts/login';
import AuthorsKnowladgeBase from './brain/setup/authorsKnowladgeBase';
import AuthorsSellSideResearch from './brain/setup/authorsSellSideResearch';
import BooksCategories from './brain/setup/booksCategories';
import CompanyList from './brain/setup/companyList';
import CountryList from './brain/setup/countryList';
import DepartmentList from './brain/setup/departmentList';
import DepartmentManuals from './brain/setup/departmentManuals';
import PolicyList from './brain/setup/policyList';
import ReportTopicSellsideResearch from './brain/setup/reportTopicSellsideResearch';
import ReportTypeSellsideResearch from './brain/setup/reportTypeSellsideResearch';
import VendorsKnowledgeBase from './brain/setup/vendorsKnowledgeBase';
import VendorsSellsideResearch from './brain/setup/vendorsSellsideResearch';
import Policiessops from './brain/policiessops';
import GeneralTrainingMaterial from './brain/generalTrainingMaterial';
import DepartmentManualsFront from './brain/departmentManualsFront';
import DepartmentSpecificTrainingMaterial from './brain/departmentSpecificTrainingMaterial';
import Magazines from './brain/magazines';
import Knowledgebase from './brain/knowledgebase';
import SellSideResearch from './brain/sellSideResearch';
import Header from './common/header';
import { createTheme, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
// import { NavigationProgress } from '@mantine/nprogress';
import '@mantine/nprogress/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import Book from './brain/book';
import { ModalsProvider } from '@mantine/modals';
function App(props) {
  const theme = createTheme({
    /* Your theme override here */
  });
  return (
    <MantineProvider theme={theme}>
      <ModalsProvider>
      <Notifications />
      {/* <NavigationProgress /> */}
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="brain" element={<Brain />} />
          <Route path="brain/setup" element={<Setup />} />
          <Route
            path="brain/setup/authorsknowladgebase"
            element={<AuthorsKnowladgeBase />}
          />
          <Route
            path="brain/setup/authorssellsideresearch"
            element={<AuthorsSellSideResearch />}
          />
          <Route
            path="brain/setup/bookscategories"
            element={<BooksCategories />}
          />
          <Route path="brain/setup/companylist" element={<CompanyList />} />
          <Route path="brain/setup/countrylist" element={<CountryList />} />
          <Route
            path="brain/setup/departmentlist"
            element={<DepartmentList />}
          />
          <Route
            path="brain/setup/departmentmanuals"
            element={<DepartmentManuals />}
          />
          <Route path="brain/setup/policylist" element={<PolicyList />} />
          <Route
            path="brain/setup/reportTopicSellsideResearch"
            element={<ReportTopicSellsideResearch />}
          />
          <Route
            path="brain/setup/reporttypesellsideresearch"
            element={<ReportTypeSellsideResearch />}
          />
          <Route
            path="brain/setup/vendorsKnowledgeBase"
            element={<VendorsKnowledgeBase />}
          />
          <Route
            path="brain/setup/vendorsSellsideResearch"
            element={<VendorsSellsideResearch />}
          />

          <Route path="brain/policiessops" element={<Policiessops />} />
          <Route
            path="brain/generaltrainingmaterial"
            element={<GeneralTrainingMaterial />}
          />
          <Route
            path="brain/departmentmanuals"
            element={<DepartmentManualsFront />}
          />
          <Route
            path="brain/departmentspecifictrainingmaterial"
            element={<DepartmentSpecificTrainingMaterial />}
          />
          <Route path="brain/books" element={<Book />} />
          <Route path="brain/magazines" element={<Magazines />} />
          <Route path="brain/knowledgebase" element={<Knowledgebase />} />
          <Route path="brain/sellsideresearch" element={<SellSideResearch />} />

          {/* <Route path="brain/*" element={<div>404</div>} /> */}
          {/* <Route path="/brain" element={<div>Brain</div>} /> */}
        </Routes>
      </Router>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;